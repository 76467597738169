export class ExternalAjaxParameter {
    constructor(name, elementId, required) {
        this.elementId = elementId;
        this.required = required;
        this._value = "";
        this.onChanged = undefined;
        this.name = name;
        this.element = $(`#${elementId}`);
        if (this.element.length === 0 && required) {
            console.error(`Element with id ${elementId} not found`);
        }
        this.element.on("change", () => this.changeValue());
        this.changeValue();
    }
    changeValue() {
        this._value = this.element.val();
        if (this.onChanged !== undefined) {
            this.onChanged();
        }
    }
    get value() {
        return this._value;
    }
    get valid() {
        return !this.required || (!!this._value && this._value !== "0");
    }
    static createFromPrefixedName(prefixedName, targetId) {
        const notRequiredPrefix = "ajaxParam";
        const requiredPrefix = "ajaxRequiredParam";
        let name = undefined;
        let required = false;
        if (prefixedName.indexOf(notRequiredPrefix) === 0) {
            name = prefixedName.slice(notRequiredPrefix.length);
        }
        if (prefixedName.indexOf(requiredPrefix) === 0) {
            name = prefixedName.slice(requiredPrefix.length);
            required = true;
        }
        if (name === undefined || name.length === 0) {
            return undefined;
        }
        name = name.charAt(0).toLowerCase() + name.slice(1);
        return new ExternalAjaxParameter(name, targetId, required);
    }
}
