import { onDocumentReady } from "./common/on-document-ready";
import { TinySlider } from "./common/tiny-slider";
import { Select } from "./common/select2/select";
import { AutoReload } from "./common/auto-reload";
import { FilterForm } from "./web/filter-form";
onDocumentReady(function () {
    initializeSelect2();
    initializeAutoReload();
    TinySlider.initialize();
    initializeSessionSearchForm();
});
function initializeSelect2() {
    Select.initialize();
}
function initializeAutoReload() {
    AutoReload.initialize();
}
function initializeSessionSearchForm() {
    new FilterForm();
}
