import { AjaxOptions } from "./ajax-options";
import { Template } from "./template";
export class Options {
    constructor(placeholder, ajax, width, searchDisabled, template, containerCssClass) {
        this.ajax = undefined;
        this.placeholder = placeholder;
        this.ajax = ajax;
        this.width = width;
        this.searchDisabled = searchDisabled;
        this.template = template;
        this.containerCssClass = containerCssClass;
    }
    static fromElementData(data, onRequiredParametersChanged) {
        const ajaxOptions = AjaxOptions.createFromElementData(data, onRequiredParametersChanged);
        return new Options(data["placeholder"], ajaxOptions, data["width"], data["searchDisabled"] === "yes", Template.fromElementData(data), data["containerCssClass"]);
    }
    get formattedOptions() {
        const options = {};
        if (this.placeholder !== undefined) {
            options["placeholder"] = this.placeholder;
        }
        if (this.width !== undefined) {
            options["width"] = this.width;
        }
        if (this.ajax !== undefined) {
            // options['disabled'] = !this.ajax.canDoRequest();
            options["ajax"] = this.ajax.formattedOptions;
        }
        if (this.searchDisabled) {
            options["minimumResultsForSearch"] = Infinity;
        }
        if (this.containerCssClass !== undefined) {
            options["dropdownCssClass"] = this.containerCssClass;
        }
        if (this.ajaxOptionForNone !== undefined) {
            options["data"] = [
                { id: this.ajaxOptionForNone.id, text: this.ajaxOptionForNone.value },
            ];
        }
        options["templateResult"] = this.template.callback;
        return options;
    }
    get isAjax() {
        return this.ajax !== undefined;
    }
    get hasToBePreloaded() {
        return this.ajax.preload;
    }
    get ajaxFormattedOptions() {
        return this.ajax.formattedOptions;
    }
    get ajaxOptionForNone() {
        if (!this.isAjax) {
            return undefined;
        }
        return this.ajax.fixedOptionForNone;
    }
}
