export class TinySlider {
    constructor() {
        tns({
            container: ".tiny-block-gallery",
            items: 4,
            slideBy: 1,
            gutter: 20,
            nav: false,
            controls: true,
            arrowKeys: true,
            autoplay: false,
            loop: true,
            rewind: true,
            controlsContainer: "#customize-controls",
            responsive: {
                0: {
                    edgePadding: 20,
                    gutter: 20,
                    items: 1,
                },
                700: {
                    items: 2,
                },
                992: {
                    items: 3,
                },
                1400: {
                    items: 4,
                },
            },
        });
    }
    static initialize() {
        if (typeof tns !== "function") {
            return;
        }
        return new TinySlider();
    }
}
