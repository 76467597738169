import { Options } from "./options";
export class Select {
    constructor(element) {
        this.element = element;
        this.extraData = {};
        const options = this.getOptions(element.data());
        $(element).select2(options.formattedOptions);
        $(element).on("select2:re-initialize", () => {
            $(this.element).select2(options.formattedOptions);
            let newValue = null;
            if (options.ajaxOptionForNone !== undefined) {
                newValue = options.ajaxOptionForNone.id;
            }
            $(this.element).val(newValue).trigger("change");
        });
        this.setDataOnSelect();
        if (options.isAjax &&
            options.hasToBePreloaded &&
            $(element).find("option").length === 0) {
            this.preload(options);
        }
    }
    setDataOnSelect() {
        $(this.element).on("select2:selecting", (e) => {
            this.extraData[e.params.args.data.id] = e.params.args.data;
        });
        $(this.element).on("change.select2", () => {
            const data = this.extraData[this.element.val()];
            const option = $(this.element).find(`option:selected`)[0];
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    option.dataset[key] = data[key];
                }
            }
        });
    }
    preload(options) {
        if (options.ajaxOptionForNone !== undefined) {
            $(this.element).append(new Option(options.ajaxOptionForNone.value, options.ajaxOptionForNone.id, false, true));
            return;
        }
        this.preloadWithAjax(options);
    }
    preloadWithAjax(options) {
        const ajaxFormattedOptions = {
            ...options.ajaxFormattedOptions,
            ...{
                success: (results) => {
                    let firstSelected = false;
                    for (const result of results.data) {
                        $(this.element).append(new Option(result.name, result.id.toLocaleString(), false, !firstSelected));
                        if (!firstSelected) {
                            $(this.element).trigger({
                                type: "select2:select",
                                params: {
                                    data: { id: result.id, text: result.name },
                                },
                            });
                        }
                        firstSelected = true;
                    }
                    $(this.element).trigger("change");
                },
            },
        };
        $.ajax(ajaxFormattedOptions);
    }
    getOptions(data) {
        return Options.fromElementData(data, (valid) => {
            $(this.element).val(null).trigger("change");
            this.element.prop("disabled", !valid);
        });
    }
    static initialize() {
        $("select.select2").each(function () {
            new Select($(this));
        });
    }
}
