export class AutoReload {
    constructor(element) {
        this.element = element;
        AutoReload.initializedElements.add(element);
        $(element).on("change", () => this.reload());
    }
    get selectedOption() {
        return $(this.element).find("option:selected");
    }
    reload() {
        const url = this.selectedOption.data("url");
        if (url) {
            window.location.href = url;
        }
    }
    static initialize() {
        AutoReload.findReloadSelects(document.body);
        new MutationObserver(function (mutationsList, observer) {
            for (const mutation of mutationsList) {
                if (mutation.type === "childList") {
                    if (mutation.target instanceof HTMLElement) {
                        AutoReload.findReloadSelects(mutation.target);
                    }
                }
            }
        }).observe(document.body, { childList: true, subtree: true });
    }
    static findReloadSelects(parent) {
        parent
            .querySelectorAll("select.auto-reload")
            .forEach((element) => {
            if (AutoReload.initializedElements.has(element)) {
                return;
            }
            new AutoReload(element);
        });
    }
}
AutoReload.initializedElements = new Set();
