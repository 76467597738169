import { ExternalAjaxParameter } from "./external-ajax-parameter";
export class AjaxOptions {
    constructor(url, fixedOptionForNone, preload, onRequiredParametersChanged) {
        this.externalParameters = [];
        this.url = url;
        this.onRequiredParametersChanged = onRequiredParametersChanged;
        this.fixedOptionForNone = fixedOptionForNone;
        this.preload = preload;
    }
    get params() {
        const params = {};
        this.externalParameters.forEach((param) => {
            params[param.name] = param.value;
        });
        return params;
    }
    addExternalParameter(parameter) {
        this.externalParameters.push(parameter);
        parameter.onChanged = () => {
            this.onRequiredParametersChanged(this.canDoRequest());
        };
    }
    canDoRequest() {
        let valid = true;
        this.externalParameters.forEach((value) => {
            if (!value.valid) {
                valid = false;
            }
        });
        return valid;
    }
    static createFromElementData(data, onRequiredParametersChanged) {
        const ajaxUrl = data["ajaxUrl"];
        if (ajaxUrl === undefined) {
            return undefined;
        }
        const fixedOptionForNoneValue = data["fixedOptionForNoneValue"] ?? "";
        const fixedOptionForNoneText = data["fixedOptionForNoneText"] ?? "";
        let fixedOptionForNone = undefined;
        if (fixedOptionForNoneText) {
            fixedOptionForNone = {
                id: fixedOptionForNoneValue,
                value: fixedOptionForNoneText,
            };
        }
        const preload = data["ajaxPreload"] === "yes";
        const options = new AjaxOptions(ajaxUrl, fixedOptionForNone, preload, onRequiredParametersChanged);
        for (const name in data) {
            if (!data.hasOwnProperty(name)) {
                continue;
            }
            const param = ExternalAjaxParameter.createFromPrefixedName(name, data[name]);
            if (param !== undefined) {
                options.addExternalParameter(param);
            }
        }
        return options;
    }
    get formattedOptions() {
        return {
            url: this.url,
            data: (params) => {
                const query = this.params;
                query["text"] = params.term;
                query["page"] = params.page;
                return query;
            },
            dataType: "json",
            type: "GET",
            processResults: (result) => {
                return {
                    results: this.formatResults(result),
                    pagination: {
                        more: result.more,
                    },
                };
            },
        };
    }
    formatResults(result) {
        const results = [];
        if (this.fixedOptionForNone !== undefined) {
            results.push({
                text: this.fixedOptionForNone.value,
                id: this.fixedOptionForNone.id,
                selected: true,
            });
        }
        return [
            ...results,
            ...$.map(result.data, function (item) {
                const extra = { ...item };
                delete extra.name;
                delete extra.id;
                return {
                    text: item.name,
                    id: item.id,
                    ...extra,
                };
            }),
        ];
    }
}
